.socialmedia {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top:30px;
    width:100%;
    margin-top: 67px;
    
    a {
        color: $white;
        display: inline-block;
        font-size: 24px;
        margin:0 15px;
    }
}