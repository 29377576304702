.overlay {
    background-color: #111;
    position: fixed;
    height:100%;
    width:100%;
    opacity: .4;
}

.Block-media {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: top;
    object-fit: cover;
    z-index: -1;
    overflow:hidden;
}