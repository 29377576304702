section.page-section {
  padding: 2rem 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  // &.section1 {
  //     z-index: 3;
  // }

  // &.section2 {
  //     z-index: 2;
  // }

  .article-container {
    display: flex;
    justify-content: center;
    align-items: center;
    //padding:2rem;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    //text-shadow: 0 0 60px #000000;

    ul {
      &.hide {
        visibility: hidden;
      }
    }
    .timesup {
      transition: all 6.2s;
      opacity: 0;
      transform: scale(0.8);
      visibility: hidden;

      font-size: 70px;
      //font-weight: 600;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      display: none;
      letter-spacing: 10px;
      &.show {
        opacity: 1;
        transform: scale(1);
        visibility: visible;
      }

      img {
        width: 400px;
        max-width: 85%;

      }
    }

    li {
      display: inline-block;
      font-size: 12px;
      list-style-type: none;
      //padding: 7%;
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
      width: 160px;

      @media screen and (max-width: 810px) {
        width: 140px;
      }

      @media screen and (max-width: 670px) {
        width: 80px;
      }
      @media screen and (max-width: 370px) {
        width: 70px;
        font-size: 10px;
      }
    }

    li span {
      display: block;
      //font-weight: 300;
      font-size: 70px;

      @media screen and (max-width: 810px) {
        font-size: 60px;
      }
      @media screen and (max-width: 670px) {
        font-size: 40px;
      }
      @media screen and (max-width: 370px) {
        font-size: 35px;
      }
    }
  }
}
