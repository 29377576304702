* {
    box-sizing: border-box;
  }
body {
    background-color: #111;
    font-family: 'Roboto', sans-serif;
    font-weight: 300; 
    color:$white;
    opacity: 0.1;
}
.wrapper {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
}